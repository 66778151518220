// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-canvas-js": () => import("./../../../src/pages/Canvas.js" /* webpackChunkName: "component---src-pages-canvas-js" */),
  "component---src-pages-full-screen-js": () => import("./../../../src/pages/FullScreen.js" /* webpackChunkName: "component---src-pages-full-screen-js" */),
  "component---src-pages-horizontal-scrolling-js": () => import("./../../../src/pages/HorizontalScrolling.js" /* webpackChunkName: "component---src-pages-horizontal-scrolling-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lexical-page-js": () => import("./../../../src/pages/LexicalPage.js" /* webpackChunkName: "component---src-pages-lexical-page-js" */),
  "component---src-pages-papers-js": () => import("./../../../src/pages/Papers.js" /* webpackChunkName: "component---src-pages-papers-js" */),
  "component---src-pages-question-js": () => import("./../../../src/pages/Question.js" /* webpackChunkName: "component---src-pages-question-js" */),
  "component---src-pages-quill-demo-js": () => import("./../../../src/pages/QuillDemo.js" /* webpackChunkName: "component---src-pages-quill-demo-js" */),
  "component---src-pages-table-drag-js": () => import("./../../../src/pages/TableDrag.js" /* webpackChunkName: "component---src-pages-table-drag-js" */),
  "component---src-pages-wordhtml-js": () => import("./../../../src/pages/wordhtml.js" /* webpackChunkName: "component---src-pages-wordhtml-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

